/* eslint-disable no-undef */
/* eslint-disable no-case-declarations */
import axios from 'axios'
import { queryCache } from 'api/query'

import store from 'store'
import { useMasqStore } from 'store'
import { resetApp, refresh } from 'store/auth/actions'

const { dispatch } = store
const undoMasq = useMasqStore.getState().undoMasq

let baseApi = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
  data: {},
})

export default baseApi

let newTokenOnTheWay = false
let unauthorizedRequests = []
function addToRequestQueue(cb) {
  unauthorizedRequests.push(cb)
}
async function onTokenRefreshed(accessToken) {
  unauthorizedRequests = unauthorizedRequests.filter((callback) =>
    callback(accessToken)
  )
}
function getAccessToken() {
  return store.getState().auth.accessToken
}

baseApi.interceptors.request.use(async (request) => {
  let accessToken = getAccessToken()
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`
  }
  return request
})

baseApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const {
      config,
      response: { status },
    } = error
    const originalRequest = config
    if (status === 401) {
      queryCache.clear()
      undoMasq()
      document.title = process.env.REACT_APP_NAME
      dispatch(resetApp())
    }
    if (status === 403) {
      if (!newTokenOnTheWay) {
        newTokenOnTheWay = true

        dispatch(refresh()).then((newAccessToken) => {
          newTokenOnTheWay = false
          onTokenRefreshed(newAccessToken)
        })
      }
      return new Promise((resolve) => {
        addToRequestQueue((token) => {
          originalRequest.headers['Authorization'] = `Bearer ${token}`
          resolve(axios(originalRequest))
        })
      })
    }
    return Promise.reject(error)
  }
)
