import auth from 'api'
import React from 'react'
import { useQuery } from 'react-query'

export const getLanguages = async () => {
  let { data } = await auth({
    method: 'get',
    url: `/guest/user/languages`,
  })
  return data
}

export const useLanguages = ({ queryOptions } = {}) => {
  return useQuery(['languages'], getLanguages, {
    cacheTime: Infinity,
    staleTime: Infinity,
    ...queryOptions,
  })
}

export const useLanguage = (id) => {
  const { data } = useLanguages({
    queryOptions: {
      select: React.useCallback(
        (data) => {
          return data.find((language) => language.id === id)
        },
        [id]
      ),
    },
  })
  return data
}
